<template>
  <div>
    <home-page-intro />

    <app-page-wrapper class="flex flex-col gap-44 pb-sections">
      <teacher-companies-logo-banner />

      <course-card-list
        id="home_course_list"
        :list="courses"
        title="Inscrivez-vous en avant première aux prochaines sessions"
      />
    </app-page-wrapper>

    <div class="bg-primary-950 py-32 text-primary-50">
      <app-page-wrapper>
        <app-page-section
          title="Relevez les défis de demain aux côtés des meilleurs d'aujourd'hui"
        >
          <ul class="grid gap-10 gap-y-16 md:grid-cols-3">
            <li v-for="item in reassuranceItems" :key="item.cover">
              <figure>
                <app-image alt="" class="rounded-lg" :src="item.cover" />
                <figcaption class="mt-8">
                  <p class="mb-3 text-2xl font-extrabold">{{ item.title }}</p>
                  <p>{{ item.subtitle }}</p>
                </figcaption>
              </figure>
            </li>
          </ul>
          <template #append>
            <search-results-button color="light" origin="home_page" size="lg" />
          </template>
        </app-page-section>
      </app-page-wrapper>
    </div>

    <div class="bg-gradient-to-b from-accent-100 to-white">
      <app-page-wrapper class="flex flex-col gap-32 py-32">
        <app-page-section v-if="tags" title="Des experts dans chaque domaine">
          <app-carousel :item-gap="1" :item-width="280" :items="tags">
            <template #item="{ item }">
              <div class="h-full p-2">
                <tag-card class="h-full" origin="home_page" :tag="item" />
              </div>
            </template>
          </app-carousel>
        </app-page-section>

        <home-session-highlights />
      </app-page-wrapper>
    </div>

    <div class="bg-gradient-to-b from-deco-100 py-32 text-deco-950">
      <app-page-wrapper>
        <brand-nps-banner>
          <template #append>
            <search-results-button color="dark" origin="home_page" size="lg" />
          </template>
        </brand-nps-banner>
      </app-page-wrapper>
    </div>

    <press-list />
  </div>
</template>

<script lang="ts" setup>
import TagCard from "~/business-areas/tag/components/tag-card.vue";
import type { I18nMessage } from "~/core/i18n/i18n.model";
import HomePageIntro from "~/pages/index/_includes/components/home-page-intro.vue";
import HomeSessionHighlights from "~/pages/index/_includes/components/home-session-highlights.vue";

const { data: tags } = useTagList(
  {
    used: true,
    depth: 0,
    sort_field: "related_courses_count",
    sort_order: "DESC",
  },
  { server: false, lazy: true },
);

const { data: courses } = useCourseCardList(
  "home_page",
  {},
  { server: false, lazy: true },
);

const reassuranceItems: {
  title: I18nMessage;
  subtitle: I18nMessage;
  cover: string;
}[] = [
  {
    title: "Leurs méthodes ont fait leur preuve",
    subtitle:
      "Ils-elles sont en poste, et parlent d’expériences concrètes. Un processus de sélection et d’accompagnement ultra exigeant.",
    cover: "/images/brand/session/interior_3.jpg",
  },
  {
    title: "De vrais échanges en personne",
    subtitle:
      "Présentiel ou distanciel, en petits groupes de professionnels sélectionnés.",
    cover: "/images/brand/session/interior_4.jpg",
  },
  {
    title: "Le tout condensé en une journée",
    subtitle:
      "Nous savons que vous êtes très occupés. Nos sessions durent 1/2 ou 1 journée pour s'insérer dans votre emploi du temps.",
    cover: "/images/brand/session/interior_5.jpg",
  },
];
</script>
