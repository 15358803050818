<template>
  <tag-select
    v-model="selected"
    class="rounded bg-white p-3 transition-shadow @container hover:shadow-lg"
  >
    <template #append="{ open }">
      <app-icon
        class="transition"
        :class="{ 'rotate-180': open }"
        icon="ph:caret-down"
        :size="20"
      />

      <div class="hidden self-stretch py-2 @md:block">
        <app-divider vertical />
      </div>

      <app-button class="grow @md:grow-0" size="lg" @click="onSubmit">
        {{ $t("tag.select_form.cta") }}
      </app-button>
    </template>
  </tag-select>
</template>

<script lang="ts" setup>
const emit = defineEmits<{
  submit: [payload: DatabaseTable<"tags">["slug"][]];
}>();
const selected = ref<DatabaseTable<"tags">["slug"][]>([]);
const { $analytics } = useNuxtApp();
const onSubmit = () => {
  $analytics.track("search_requested", { tags: selected.value });
  emit("submit", selected.value);

  navigateTo({
    name: "search",
    query: {
      tags: selected.value,
    },
  });
};
</script>
