<template>
  <nuxt-link class="relative block aspect-square" :to="definition.coursePage">
    <component v-bind="bind" :is="component" class="absolute inset-0" />
  </nuxt-link>
</template>

<script lang="ts" setup>
import type { BrandPeopleGridCellDefinition } from "~/business-areas/brand/brand.model";

const AppImage = resolveComponent("app-image");

const properties = defineProps<{
  definition: BrandPeopleGridCellDefinition;
}>();

const isVideo = computed(() => properties.definition.src.slice(-3) === "mp4");
const component = computed(() => (isVideo.value ? "video" : AppImage));

const bind = computed(() => {
  if (isVideo.value)
    return {
      muted: true,
      autoplay: true,
      loop: true,
      preload: "metadata",
      src: useCloudinaryVideo(properties.definition.src, {
        width: 400,
        height: 400,
      }).value,
    };

  return {
    alt: properties.definition.fullName ?? "Illustration",
    src: properties.definition.src,
  };
});
</script>
