<template>
  <app-page-section title="Les moments forts d’une session Pollen">
    <ul
      class="m-auto grid max-w-[500px] place-content-center gap-x-[4vw] gap-y-16 lg:max-w-none lg:grid-cols-3"
    >
      <li
        v-for="item in sessionHighlights"
        :key="item.title"
        class="relative p-4 text-center"
      >
        <div
          class="absolute left-1/2 top-0 aspect-square w-[70%] -translate-x-1/2"
          :class="item.shapeClass"
          :style="{ clipPath: item.shape }"
        />

        <figure class="relative">
          <app-image alt="" class="rounded-lg" :src="item.cover" :width="500" />

          <figcaption class="mt-8 font-semibold">
            <p class="mb-2">{{ item.title }}</p>

            <app-chip :class="item.shapeClass" size="small">{{
              item.tag
            }}</app-chip>
          </figcaption>
        </figure>
      </li>
    </ul>

    <template #append
      ><search-results-button color="dark" origin="home_page" size="lg"
    /></template>
  </app-page-section>
</template>

<script lang="ts" setup>
import {
  BRAND_SHAPE_CIRCLE,
  BRAND_SHAPE_HALF_BOTTOM_CIRCLE,
  BRAND_SHAPE_HALF_TOP_CIRCLE,
} from "~/business-areas/brand/brand-shapes";
import type { I18nMessage } from "~/core/i18n/i18n.model";

const sessionHighlights: {
  title: I18nMessage;
  tag: I18nMessage;
  cover: string;
  shape: string;
  shapeClass: string;
}[] = [
  {
    tag: "Atelier",
    title:
      "Des ateliers de groupe pour progresser rapidement à plusieurs sur des cas concrets",
    cover: "/images/brand/session/interior_2.jpg",
    shape: BRAND_SHAPE_HALF_BOTTOM_CIRCLE,
    shapeClass: "bg-accent-100",
  },
  {
    title: "Rencontrer une communauté et continuer à échanger après la session",
    tag: "Communauté",
    cover: "/images/brand/session/exterior.jpg",
    shape: BRAND_SHAPE_CIRCLE,
    shapeClass: "bg-deco2-100",
  },
  {
    tag: "Outils",
    title:
      "Repartir avec l’expérience du trainer, ses méthodes, ses frameworks, ses outils",
    cover: "/images/brand/session/interior.jpg",
    shape: BRAND_SHAPE_HALF_TOP_CIRCLE,
    shapeClass: "bg-deco-100",
  },
];
</script>
