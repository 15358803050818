import type { BrandPeopleGridCellDefinition } from "~/business-areas/brand/brand.model";

const list = new Map<
  "men" | "women",
  Omit<BrandPeopleGridCellDefinition, "color">[]
>([
  [
    "men",
    [
      {
        fullName: "Raphael Moutard",
        job: "Ex software Engineering Manager @Spendesk",
        src: "https://res.cloudinary.com/dfdgwwkpd/video/upload/v1686153963/brand/home/Raphae%CC%88l_Moutard_optimized_online-video-cutter.com_bvwdrd.mp4",
        coursePage: "/courses/devenir-engineering-manager",
      },
      {
        fullName: "Benjamin Retourné",
        job: "VP Product @BlaBlaCar",
        src: "https://res.cloudinary.com/dfdgwwkpd/video/upload/v1686066374/brand/home/BENJAMIN_RETOURNE_V1_online-video-cutter.com_orya3s.mp4",
        coursePage: "/courses/construire-une-roadmap-produit",
      },
      {
        fullName: "Jean Moreau",
        job: "CEO @Phenix",
        src: "https://res.cloudinary.com/dfdgwwkpd/video/upload/v1686043988/brand/home/s2hfsp6katrnfl0bcovt_online-video-cutter.com_1_mcxvkl.mp4",
        coursePage: "/courses/comment-scaler-une-boite-a-impact",
      },
      {
        fullName: "Vladimir Ionesco",
        job: "Director Sales & Performance @Doctolib",
        src: "https://res.cloudinary.com/dfdgwwkpd/video/upload/v1686045306/brand/home/Vladimir_Ionesco_VDEF_online-video-cutter.com_1_jybz4d.mp4",
        coursePage: "/courses/booster-sa-performance-commerciale",
      },
    ],
  ],
  [
    "women",
    [
      {
        fullName: "Claire Lebarz",
        job: "VP Data @Malt",
        src: "https://res.cloudinary.com/dfdgwwkpd/video/upload/v1686066133/brand/home/ClAIRE_LEBARZ_V1_online-video-cutter.com_pmf8ik.mp4",
        coursePage: "/courses/construire-des-produits-avec-lia",
      },
      {
        fullName: "Elodie Champagnat",
        job: "CMO @Seyna",
        src: "https://res.cloudinary.com/dfdgwwkpd/video/upload/v1686066254/brand/home/POLEN_ELODIE_CHAMPAGNAT_V_DEF_online-video-cutter.com_lxmwhb.mp4",
        coursePage: "/courses/b2b-product-marketing-management-boost",
      },
    ],
  ],
]);

const liveSessionVideo: Omit<BrandPeopleGridCellDefinition, "color"> = {
  fullName: "How to tell impactful stories",
  job: "with Jamie Wong",
  src: "https://res.cloudinary.com/dfdgwwkpd/video/upload/v1688637258/brand/home/enterprise_cover_online-video-cutter.com_ihvhf5.mp4",
  coursePage: "/courses/how-to-tell-impactful-stories",
};

export const useHomePageHighlightedTrainers =
  (): BrandPeopleGridCellDefinition[] => {
    const men = shuffle(list.get("men") ?? []).slice(0, 2);
    const women = shuffle(list.get("women") ?? []).slice(0, 1);

    return shuffle([liveSessionVideo, ...men, ...women]).map((item) => {
      return {
        ...item,
        color: useStringToColor(item.fullName, {
          saturation: 70,
          luminance: 40,
        }).value,
      };
    });
  };
