<template>
  <div
    ref="wrapper"
    class="group relative grid cursor-none gap-2"
    :class="{
      'grid-cols-[repeat(var(--columnCount),1fr)]': !!columns,
    }"
    :style="{
      '--columnCount': columns,
    }"
    @mouseleave="onCellHover()"
  >
    <div
      v-for="(cell, index) in cells"
      :key="cell.fullName"
      @mouseenter="onCellHover(cell)"
    >
      <brand-people-grid-cell
        class="cursor-none transition-all duration-700"
        :definition="cell"
        :style="{
          clipPath: instanceShapes[index],
          transitionDelay: `${transitionDelays[index]}ms`,
        }"
      />
    </div>

    <div
      v-if="hoveredCell"
      class="absolute left-[--left] top-[--top] grid -translate-x-full translate-y-2 justify-items-end"
      :style="{
        '--left': `${elementX}px`,
        '--top': `${elementY}px`,
        '--color': `${hoveredCell?.color}`,
      }"
    >
      <app-icon
        class="translate-x-2 rotate-[60deg] text-[--color]"
        icon="fluent:cursor-16-filled"
      />

      <div
        class="whitespace-nowrap rounded bg-[--color] px-4 py-2 text-white transition-colors"
      >
        <p class="font-bold">
          {{ hoveredCell?.fullName }}
        </p>
        <p class="text-sm">{{ hoveredCell?.job }}</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { BrandPeopleGridCellDefinition } from "~/business-areas/brand/brand.model";
import * as availableShapes from "~/business-areas/brand/brand-shapes";

const getRandomShapeArray = (length: number) => {
  return shuffle(Object.values(availableShapes)).slice(0, length);
};

const wrapperElement = templateRef<HTMLElement>("wrapper");
const { elementY, elementX } = useMouseInElement(wrapperElement);

const SHAPE_ANIMATION_INTERVAL_IN_MS = 2500;

const properties = defineProps<{
  columns?: number;
  cells: BrandPeopleGridCellDefinition[];
}>();

const hoveredCell = ref<BrandPeopleGridCellDefinition | undefined>();

const instanceShapes = ref(getRandomShapeArray(properties.cells.length));

const onCellHover = (cell?: BrandPeopleGridCellDefinition) => {
  hoveredCell.value = cell;
};

const transitionDelays = ref(
  Array.from({ length: properties.cells.length }).map((_, index) => {
    return (index * 100) / properties.cells.length;
  }),
);

setInterval(() => {
  instanceShapes.value = getRandomShapeArray(properties.cells.length);
  transitionDelays.value = shuffle(transitionDelays.value);
}, SHAPE_ANIMATION_INTERVAL_IN_MS);
</script>
