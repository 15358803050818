<template>
  <nuxt-link
    v-if="tag"
    class="flex rounded outline-none focus-visible:ring-2 focus-visible:ring-[--hoverColor]"
    :style="{
      '--color': tagColor,
      '--hoverColor': hoverColor,
    }"
    :to="{
      name: 'courses-slug',
      params: {
        slug: course.slug,
      },
    }"
  >
    <tag-chip
      class="!bg-[--color] transition-colors hover:!bg-[--hoverColor]"
      :size="size"
      :tag="tag"
    >
      {{ course.subject }}
    </tag-chip>
  </nuxt-link>
</template>

<script lang="ts" setup>
import type { PinnedCourseView } from "~~/model/course.model";

const properties = defineProps<{
  course: PinnedCourseView;
  size?: "small";
}>();

const tag = computed(() => properties.course.tags[0]?.value);

const tagColor = useStringToColor(
  computed(() => properties.course.title),
  {
    saturation: 70,
    luminance: 90,
  },
);
const hoverColor = useStringToColor(
  computed(() => properties.course.title),
  {
    saturation: 70,
    luminance: 85,
  },
);
</script>
